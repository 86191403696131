<template>
    <icon
        class="ui-check-icon"
        :src="`img/common/check${checked ? '-on' : '' }.png`"
        :width="$props.width"
        :height="$props.height"
        :cover="$props.cover"
        @click="onClick"
    />
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
    name: 'CheckIcon',
    components: { Icon },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [String, Number],
            default: '25px',
        },
        height: {
            type: [String, Number],
            default: '25px',
        },
        cover: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            checked: false,
        }
    },
    watch: {
        '$props.value'(value) {
            this.checked = value === true
            this.$emit('input', this.checked)
        },
    },
    mounted() {
        this.checked = this.$props.value === true
    },
    methods: {
        onClick() {
            this.checked = !this.checked
            this.$emit('input', this.checked)
            this.$emit('click', this.checked)
        },
    },
}
</script>
