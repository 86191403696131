<template>
    <div class="container">
        <page-header :title="`헤이비글 안전결제,\n수수료 안내`" titleSize="3.4rem">
            <span slot="subtitle" style="font-size: 1,9rem">헤이비글은 안전한 거래와 합리적인 수수료를 지향합니다.</span>
        </page-header>

        <div class="ui-border-line ui-h-1 ui-mb-3" style="height: 10px"></div>

        <h4>안전결제 및 수수료 안내</h4>

        <div class="ui-border-line ui-h-0 ui-mb-2"></div>

        <div class="FeeInformationAndAgreement-checkBlock">
            <template>
                <check-icon width="30px" height="30px" v-model="checked[0]" />
            </template>
            <div @click="() => { checked[0] = !checked[0] }">
                헤이비글은 의뢰인과 전문가가 안심하고 <br>
                거래할 수 있도록 고객 만족도가 매우 높은 <br>
                안전결제 방식(에스크로)을 채택, 사용 중입니다.<br>
                * 선 결제 받고 행사 종료 후 지급하는 방식<br>
                <br>
                헤이비글에서의 모든 거래는 헤이비글 안전결제를<br>
                사용하며 그 외의 방식은 허용하지 않습니다.<br>
                <br>
            </div>
        </div>

        <div class="FeeInformationAndAgreement-checkBlock">
            <template>
                <check-icon width="30px" height="30px" v-model="checked[1]" />
            </template>
            <div @click="() => { checked[1] = !checked[1] }">
                헤이비글 수수료는 <br>
                전문가가 수익을 얻는 과정에서 이용한<br>
                헤이비글의 인프라(시스템, 의뢰자 및 일감 정보, <br>
                홍보 대행, 매니징 등 물질적, 인적 자원) 사용료로<br>
                전문가는 수수료 지불의 의무가 있습니다.<br>
                <br>
            </div>
        </div>

        <div class="FeeInformationAndAgreement-checkBlock">
            <template>
                <check-icon width="30px" height="30px" v-model="checked[2]" />
            </template>
            <div @click="() => { checked[2] = !checked[2] }">
                섭외/결제된 건에 대해 아래의 수수료를 적용하며<br>
                수수료 및 원천세 등을 차감 후 지급합니다.<br>
                - 일반 의뢰 : {{$config.constant.fees.hbFeePer}}%<br>
                - 헤이비글이 직접 수주한 의뢰 (H뱃지) : {{$config.constant.fees.hbFeePerForUserReferrer}}%<br>
                - 공급가(부가세 제외)에서 수수료를 차감 <br>
                - 개인의 경우 원천세 3.3% 차감<br>
                - 대금 지급<br>
                &nbsp;&nbsp;1일~15일 진행건 : 23일에 지급<br>
                &nbsp;&nbsp;16일~말일 진행건 : 익월 9일에 지급<br>
                &nbsp;&nbsp;* 지급일이 휴일인 경우 다음 영업일에 지급<br>
                <br>
            </div>
        </div>

        <footer-box
            submitText="다음"
            :submitCb="() => $router.push('/artist/my-profile/fee-information-and-agreement/3')"
            :submitDisabled="!valid"
            >
        </footer-box>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import CheckIcon from '@/components/common/CheckIcon'
import Modal from '@/components/common/Modal'

export default {
    components: {
        PageHeader,
        FooterBox,
        CheckIcon,
        Modal,
    },
    data() {
        const  artistAccountInfo =  this.$store.getters.getArtistAccountInfo
        return {
            checked: {
                0: artistAccountInfo.agreeDate > 0,
                1: artistAccountInfo.agreeDate > 0,
                2: artistAccountInfo.agreeDate > 0,
            },
        }
    },
    computed: {
        valid() {
            return Object.values(this.checked).every(checked => checked)
        },
        artistAccountInfo() {
            return this.$store.getters.getArtistAccountInfo
        },
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            try {
                const pageCache = JSON.parse(sessionStorage.getItem('pageCache:FeeInformationAndAgreement2'))
                for (const key in pageCache) {
                    const val = pageCache[key]
                    vm.$set(vm, key, val)
                }
            } finally {
                sessionStorage.removeItem('pageCache:FeeInformationAndAgreement2')
            }
        })
    },
    beforeRouteLeave (to, from, next) {
        const pageCache = JSON.stringify({ checked: this.checked })
        sessionStorage.setItem('pageCache:FeeInformationAndAgreement2', pageCache)
        next()
    },
}
</script>

<style lang="scss" scoped>
h4 {
    margin: 0 !important;
    font-weight: 500 !important;
    font-size: 2.6rem !important;
    line-height: 6.6rem !important;
}

input,
textarea {
    padding: 0;
    width: 100%;
    border: 0;
    font-size: 2rem;
    line-height: 10rem;
}

textarea {
    line-height: 3rem;
    min-height: 14rem;
    padding: 3rem 0;
}

p {
    color: #5a5a5a;
    font-size: 18px;
    line-height: 2;
}

.FeeInformationAndAgreement-checkBlock {
    overflow: hidden;
    > .ui-check-icon {
        float: left;
        margin-right: 15px;
    }
    > div {
        float: left;
        font-size: 1.9rem;
        color: #000;
        line-height: 1.5
    }
}
</style>
