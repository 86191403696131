var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "page-header",
        {
          attrs: {
            title: "헤이비글 안전결제,\n수수료 안내",
            titleSize: "3.4rem"
          }
        },
        [
          _c(
            "span",
            {
              staticStyle: { "font-size": "1,9rem" },
              attrs: { slot: "subtitle" },
              slot: "subtitle"
            },
            [_vm._v("헤이비글은 안전한 거래와 합리적인 수수료를 지향합니다.")]
          )
        ]
      ),
      _c("div", {
        staticClass: "ui-border-line ui-h-1 ui-mb-3",
        staticStyle: { height: "10px" }
      }),
      _c("h4", [_vm._v("안전결제 및 수수료 안내")]),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" }),
      _c(
        "div",
        { staticClass: "FeeInformationAndAgreement-checkBlock" },
        [
          [
            _c("check-icon", {
              attrs: { width: "30px", height: "30px" },
              model: {
                value: _vm.checked[0],
                callback: function($$v) {
                  _vm.$set(_vm.checked, 0, $$v)
                },
                expression: "checked[0]"
              }
            })
          ],
          _c(
            "div",
            {
              on: {
                click: function() {
                  _vm.checked[0] = !_vm.checked[0]
                }
              }
            },
            [
              _vm._v(" 헤이비글은 의뢰인과 전문가가 안심하고 "),
              _c("br"),
              _vm._v(" 거래할 수 있도록 고객 만족도가 매우 높은 "),
              _c("br"),
              _vm._v(" 안전결제 방식(에스크로)을 채택, 사용 중입니다."),
              _c("br"),
              _vm._v(" * 선 결제 받고 행사 종료 후 지급하는 방식"),
              _c("br"),
              _c("br"),
              _vm._v(" 헤이비글에서의 모든 거래는 헤이비글 안전결제를"),
              _c("br"),
              _vm._v(" 사용하며 그 외의 방식은 허용하지 않습니다."),
              _c("br"),
              _c("br")
            ]
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "FeeInformationAndAgreement-checkBlock" },
        [
          [
            _c("check-icon", {
              attrs: { width: "30px", height: "30px" },
              model: {
                value: _vm.checked[1],
                callback: function($$v) {
                  _vm.$set(_vm.checked, 1, $$v)
                },
                expression: "checked[1]"
              }
            })
          ],
          _c(
            "div",
            {
              on: {
                click: function() {
                  _vm.checked[1] = !_vm.checked[1]
                }
              }
            },
            [
              _vm._v(" 헤이비글 수수료는 "),
              _c("br"),
              _vm._v(" 전문가가 수익을 얻는 과정에서 이용한"),
              _c("br"),
              _vm._v(" 헤이비글의 인프라(시스템, 의뢰자 및 일감 정보, "),
              _c("br"),
              _vm._v(" 홍보 대행, 매니징 등 물질적, 인적 자원) 사용료로"),
              _c("br"),
              _vm._v(" 전문가는 수수료 지불의 의무가 있습니다."),
              _c("br"),
              _c("br")
            ]
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "FeeInformationAndAgreement-checkBlock" },
        [
          [
            _c("check-icon", {
              attrs: { width: "30px", height: "30px" },
              model: {
                value: _vm.checked[2],
                callback: function($$v) {
                  _vm.$set(_vm.checked, 2, $$v)
                },
                expression: "checked[2]"
              }
            })
          ],
          _c(
            "div",
            {
              on: {
                click: function() {
                  _vm.checked[2] = !_vm.checked[2]
                }
              }
            },
            [
              _vm._v(" 섭외/결제된 건에 대해 아래의 수수료를 적용하며"),
              _c("br"),
              _vm._v(" 수수료 및 원천세 등을 차감 후 지급합니다."),
              _c("br"),
              _vm._v(
                " - 일반 의뢰 : " +
                  _vm._s(_vm.$config.constant.fees.hbFeePer) +
                  "%"
              ),
              _c("br"),
              _vm._v(
                " - 헤이비글이 직접 수주한 의뢰 (H뱃지) : " +
                  _vm._s(_vm.$config.constant.fees.hbFeePerForUserReferrer) +
                  "%"
              ),
              _c("br"),
              _vm._v(" - 공급가(부가세 제외)에서 수수료를 차감 "),
              _c("br"),
              _vm._v(" - 개인의 경우 원천세 3.3% 차감"),
              _c("br"),
              _vm._v(" - 대금 지급"),
              _c("br"),
              _vm._v(" 1일~15일 진행건 : 23일에 지급"),
              _c("br"),
              _vm._v(" 16일~말일 진행건 : 익월 9일에 지급"),
              _c("br"),
              _vm._v(" * 지급일이 휴일인 경우 다음 영업일에 지급"),
              _c("br"),
              _c("br")
            ]
          )
        ],
        2
      ),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.$router.push(
              "/artist/my-profile/fee-information-and-agreement/3"
            )
          },
          submitDisabled: !_vm.valid
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }