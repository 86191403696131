var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("icon", {
    staticClass: "ui-check-icon",
    attrs: {
      src: "img/common/check" + (_vm.checked ? "-on" : "") + ".png",
      width: _vm.$props.width,
      height: _vm.$props.height,
      cover: _vm.$props.cover
    },
    on: { click: _vm.onClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }